import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Avatar,
  Typography,
  Grid,
  Box,
  Divider,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { CardProduct } from 'components/organisms';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  cardProduct: {
    display: 'flex',
    height: '100%',
    borderRadius: theme.spacing(1),
    '& .card-product__content, & .card-product__media': {
      flex: '1 1 50%',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
      '& .card-product__content, & .card-product__media': {
        flex: '1 1 100%',
      },
    },
  },
  cardProductReverse: {
    flexDirection: 'row-reverse',
    '& .card-product__media img': {
      borderRadius: theme.spacing(0, 0, 0, 20),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 20, 0),
    width: '100%',
    height: '100%'
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.secondary.light,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
}));

const Horizontal = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const BlogMediaContent = ({ src }) => {
    return (
      <StaticImage
        placeholder="blurred"
        src={src}
        // src='../../../../../assets/images/qeerio/productionbook/sections/contactsection.webp'
        alt="Section image"
        className={classes.image}
        loading="lazy"
      />
    )
  };

  const BlogContent = props => (
    <div className={classes.blogContent}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        {props.title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        {props.subtitle}
      </Typography>
      <div style={{ flexGrow: 1 }} />
      <Divider className={classes.divider} />
      <div className={classes.list}>
        <Box mb={2}>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom align="left">
            Example usage
          </Typography>
        </Box>
        <div className={classes.tags}>
          {props.tags.map((item, index) => (
            <Typography
              variant="caption"
              color="primary"
              className={classes.tag}
              key={index}
            >
              {item}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} key={'Contacts'} data-aos="fade-up">
            <CardProduct
              withShadow
              liftUp
              className={clsx(
                classes.cardProduct
              )}
              mediaContent={
                <StaticImage
                  placeholder="blurred"
                  src='../../../../../assets/images/qeerio/productionbook/sections/contactsection.webp'
                  alt='Contacts'
                  className={classes.image}
                  loading="lazy"
                />
              }
              cardContent={
                <BlogContent
                  title='Contacts'
                  subtitle="A section to store your event\' s contact list with shortcuts to quickly call, message or whatsapp"
                  tags={['Call', 'Text', 'Whatsapp', 'Find the person in charge for something', 'Quick share contact']}
                />
              }
            />
        </Grid>
        <Grid item xs={12} key={'Locations'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct,
              classes.cardProductReverse
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/locationsection.webp'
                alt='Locations'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Locations'
                subtitle="A section to store your event\' s location with shortcuts to quickly get direction with your favorite GPS app"
                tags={['Get adresses', 'Get directions', 'Quick share address', 'Waze', 'Google maps']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'Timeline'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/timelinesection.webp'
                alt='Timeline'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Timeline'
                subtitle='A timeline regrouping all keys moments of your event'
                tags={['Get event planning', 'Set reminder notifications']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'Todolist'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct,
              classes.cardProductReverse
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/todo.webp'
                alt='Todo list'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Todo list'
                subtitle='A section to list all important tasks that need to be done on your event!'
                tags={['List tasks', 'Notify task assignee', 'Prioritize tasks']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'Chat'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/chatsection.webp'
                alt='Chat'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Chat'
                subtitle='Discuss and share important new information about the event. No switching to a messaging app and add all event actors to a group chat'
                tags={['Share new informations', 'Share pictures', 'Avoid using external chat app']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'Simple list'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct,
              classes.cardProductReverse
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/listsection.webp'
                alt='Simple list'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Simple list'
                subtitle='A flexible section allowing to store anykind of information as a list of items.
                '
                tags={['List of materials', 'Pre-event todo list', 'Shuttle list', 'Hotel list', 'And many more...']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'Simple pdf'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/singlepdfsection.webp'
                alt='Simple document'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='Simple documents'
                subtitle='A section giving quick access to a document(pdf or image) containing key information about your event'
                tags={['Seating plan', 'Main menu', 'Any information on pdf']}
              />
            }
          />
        </Grid>
        <Grid item xs={12} key={'List of pdf'} data-aos="fade-up">
          <CardProduct
            withShadow
            liftUp
            className={clsx(
              classes.cardProduct,
              classes.cardProductReverse
            )}
            mediaContent={
              <StaticImage
                placeholder="blurred"
                src='../../../../../assets/images/qeerio/productionbook/sections/multipdfsection.webp'
                alt='List of documents'
                className={classes.image}
                loading="lazy"
              />
            }
            cardContent={
              <BlogContent
                title='List of documents'
                subtitle='A flexible section allowing you to store multiple documents(pdf or images) structured as a list.'
                tags={['Blueprints event build up', 'Table plans', 'Travel documents', 'Call sheets', 'Any information on pdf']}
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

Horizontal.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Horizontal;
